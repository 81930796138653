import React, { Component } from 'react';
import Swal from 'sweetalert2';

class Secc_contacto extends Component {
    constructor() {
        super();

        this.state = {
            objetosLs: [],
        };
    }

    enviar = () => {
        document.getElementById("btn_enviar").disabled = true;
        var nombre = document.getElementById("nombre").value;
        var correo = document.getElementById("correo").value;
        var telefono = document.getElementById("telefono").value;
        var comentarios = document.getElementById("comentarios").value;

        if( !nombre || !correo || !telefono || !comentarios ){
            Swal.fire("Error", "Llene todos los campos", "warning");
            document.getElementById("btn_enviar").disabled = false;
        }
        else{

        let data = {
            cuerpo: "Asunto:<strong>Contacto de invitado</strong><br/><br/>Nombre: " + nombre + " <br/>Correo: " + correo + " <br/>Teléfono: " + telefono + " <br/>Comentarios: " + comentarios,
            email: "contacto@grupodiniz.com.mx",
            asunto: "Contacto de invitado Portal ¡Kataplum!"
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.props.servurlbase + '/sendCorreoContacto.php', requestInfo)
        .then((response) => {
            if(response){
                Swal.fire(
                    'Enviado',
                    'Se ha enviado su información',
                    'success'
                )
                document.getElementById("btn_enviar").disabled = false;
                document.getElementById("nombre").value = '';
                document.getElementById("correo").value = '';
                document.getElementById("telefono").value = '';
                document.getElementById("comentarios").value = '';
            }
            else{
                Swal.fire("Error", "Error", "warning");
                document.getElementById("btn_enviar").disabled = false;
            }
        })
        .catch(e => console.log(e));
     }
    }

    render() {
        return (
            <div>
             <div className='container-fluid'>
              <div className='row row_ bg-info'>
                <div className="col-sm-12 fiestas">
                    <h3>Contáctanos</h3>
                </div>

                <div className="col-sm-3"></div>
                <div className="col-sm-6 ">
                 <p className='text-white'>Nombre completo:</p>
                 <input type="text" id='nombre' style={{textAlign:'center'}} className="form-control"/>
                 <p className='text-white'>Teléfono:</p>
                 <input type="number" id='telefono' style={{textAlign:'center'}} className="form-control"/>
                 <p className='text-white'>Correo electrónico:</p>
                 <input type="text" id='correo' style={{textAlign:'center'}} className="form-control"/>
                 <p className='text-white'>Comentarios:</p>
                 <textarea id='comentarios' className="form-control"></textarea>
                 <br/>
                </div>
                <div className="col-sm-3"></div>

                <div className="col-sm-3"></div>
                <div className="col-sm-1 col-1 text-center"><input className='form-check-input'type="checkbox"/></div>
                <div className="col-sm-5 col-11"><p className='text-white'>Acepto recibir información sobre novedades y promociones de ¡KATAPLUM!</p></div>
                <div className="col-sm-3"></div>
                <div className="col-sm-12 text-center">
                 <button className="btn btn-light" id='btn_enviar' onClick={this.enviar.bind(this)}>Enviar</button>
                </div>
                <div className="col-sm-12">
                     <br/><br/><br/><br/><br/><br/>
                </div>
              </div>
             </div>
            </div>
        )
    }

}
export default Secc_contacto