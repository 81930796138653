import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import "./desktop.css";
import { BrowserRouter as Router, Route, Switch, Link  } from 'react-router-dom';

class Secc_paraDivertirte extends Component {
    constructor() {
        super();

        this.state = {
        };
        
    }

    render() {
        return (
            <div>
              <div className='container-fluid'>
                <div className='row row_' style={{ background:'#b0d0ec' }}>
                 <div className='col-sm-12 fiestas'>
                    <h3>
                    ¡Para divertirte!
                    </h3>
                 </div>
                 <div className="col-sm-12">
                  <img className="img-fluid" src={this.props.servurlassets + "Mapa1.png"} alt='Mapa'/>
                 </div>
                 <div className='col-sm-2'></div>
                 <div className='col-sm-8'>
                    <Accordion defaultActiveKey="none">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><img  alt='Mapa' width={30} src={this.props.servurlassets + "huella.png"} />&nbsp;Renta y servicios.</Accordion.Header>
                            <Accordion.Body>
                                <ul className="list-group list-group-horizontal-xxl" style={{cursor:'pointer'}}>
                                    <Link to='/Servicios'><li className="list-group-item" >Lockers</li></Link>
                                    <Link to='/Servicios'><li className="list-group-item" >Dino rides</li></Link>
                                    <Link to='/Servicios'><li className="list-group-item" >Experiencia del invitado</li></Link>
                                    <Link to='/Servicios'><li className="list-group-item" >Radio ¡KATAPLUM!</li></Link>
                                    <Link to='/Servicios'><li className="list-group-item" >Cajeros automáticos</li></Link>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><img width={30} alt='Mapa' src={this.props.servurlassets + "fast-food.png"} />&nbsp;Alimentos y bebidas.</Accordion.Header>
                            <Accordion.Body>
                                <ul className="list-group list-group-horizontal-xxl" style={{cursor:'pointer'}}>
                                    <Link to='/Restaurantes'><li className="list-group-item">Wing's Army</li></Link>
                                    <Link to='/Restaurantes'><li className="list-group-item">Isla ICEE</li></Link>
                                    <Link to='/Kioscos'><li className="list-group-item">Botanotas</li></Link>
                                    <Link to='/Kioscos'><li className="list-group-item">Fábrica de esponjositos</li></Link>
                                    <Link to='/Restaurantes'><li className="list-group-item">Café Punta del Cielo</li></Link>
                                    <Link to='/Restaurantes'><li className="list-group-item">Sports Bar</li></Link>
                                    <Link to='/Restaurantes'><li className="list-group-item">Área de comida rápida</li></Link>
                                    <Link to='/Restaurantes'><li className="list-group-item">Dairy Queen</li></Link>
                                    <Link to='/Restaurantes'><li className="list-group-item">Kiosco Monchis</li></Link>
                                    <Link to='/Restaurantes'><li className="list-group-item">Kiosco Dulce Tatis</li></Link>
                                    <Link to='/Restaurantes'><li className="list-group-item">Dulcería Kata Club</li></Link>
                                    <Link to='/Restaurantes'><li className="list-group-item">Barcelería Don Mezclas</li></Link>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><img width={30} alt='Mapa' src={this.props.servurlassets + "teatro.png"} />&nbsp;Shows y eventos.</Accordion.Header>
                            <Accordion.Body>
                                <ul className="list-group list-group-horizontal-xxl" style={{cursor:'pointer'}}>
                                    <Link to='/Atracciones'><li className="list-group-item">Foro Insomnio</li></Link>
                                    <Link to='/Atracciones'><li className="list-group-item">Teatro cañones</li></Link>
                                    <Link to='/Atracciones'><li className="list-group-item">Teatro infantil</li></Link>
                                    <Link to='/Atracciones'><li className="list-group-item">Auditorio</li></Link>
                                    <Link to='/Atracciones'><li className="list-group-item">Salón de fiestas</li></Link>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                 </div>
                 <div className='col-sm-2'></div>
                 <div className='col-sm-12'>
                   <br/><br/><br/><br/><br/>
                  </div>
                </div>
              </div>
            </div>
        )
    }

}
export default Secc_paraDivertirte