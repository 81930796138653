import React, { Component } from 'react';
import {Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,Row} from 'reactstrap';
import './desktop.css';

class ModalTerminosCondiciones extends Component{

    constructor() {
        super();
        this.state ={
            objetosTC:[]
     }

    }

    tyc = () => {
        let data = {
           id:this.props.id 
        };

        const requestInfo = {
            method: "POST",
            body: JSON.stringify(data),
            header: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
        };
        fetch(this.props.servurlbase + "/getTerminosCondiciones.php", requestInfo)
            .then((response) => response.json())
            .then((objetosTC) => {
                if (objetosTC !== null) {
                    this.setState({ objetosTC });
                } 
            })
            .catch((e) => console.log(e));
    };

    componentDidMount() {
        this.tyc();
    }
    
    render(){
        
        return(
            <div>
                <Modal className='modal-lg' isOpen={true} toggle={this.props.cierra}>
                    <ModalHeader  className='text-white' style={{ background:'#692894' }} toggle={this.props.cierra}>
                        Términos y condiciones
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                         <Col className="col-sm-12">
                         {this.state.objetosTC.length > 0
                        ? this.state.objetosTC.map((objetos) => ( 
                            
                           <div style={{textAlign:'justify'}}>
                            <pre className='fw-bold morado'>{objetos.descripcion}</pre>
                           </div>
                        ))
                    : []}
                         </Col>
                        </Row>
                    </ModalBody>
                </Modal>    
            </div>
        );
    }    
}

export default ModalTerminosCondiciones