import React, { Component } from 'react';

class Mercado_pago extends Component {
    getPayURL=()=>{
        let data = {
            id: this.props.id,
            producto: this.props.producto,
            monto: this.props.precio,
            carrito: this.props.carrito,
        }

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.props.servurlbase + '/getPayUrlMP.php', requestInfo)
        .then(response => response.json())
        .then(response => {
            window.open(response.urlPay, "_self");
        }).catch(err => {
            console.error(err)
        });
    }

    componentDidMount() {
     this.getPayURL();
     console.log(this.props);
    }

    render() {
        return (
            <div>
              
            </div>
        );
    }
}
export default Mercado_pago;