import React, { Component } from 'react';

class Secc_Horarios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false
        };
    }

    componentDidMount() {
        this.checkScreenWidth();
        window.addEventListener("resize", this.checkScreenWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkScreenWidth);
    }

    checkScreenWidth = () => {
        const isMobile = window.innerWidth <= 768; // Puedes ajustar este valor según lo desees
        this.setState({ isMobile });
    };

    render() {
        const { isMobile } = this.state;
        const desktopImage = this.props.servurlassets + 'horarios-desktop.jpg';
        const mobileImage = this.props.servurlassets + 'horarios-mobile.jpg';

        return (
            <div style={{ height: '100vh', margin: 0, padding: 0 }}>
                <div className='col-sm-12' style={{ height: '100vh', margin: 0, padding: 0 }}>
                    {isMobile ? (
                        <img 
                            className='border border-warning' 
                            style={{ width: '100%',marginTop:'15%'}} 
                            src={mobileImage} 
                            alt="Horarios"
                        />
                    ) : (
                        <img 
                            className='border border-warning' 
                            style={{ height: '85%', objectFit: 'cover' }} 
                            src={desktopImage} 
                            alt="Horarios"
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default Secc_Horarios;
