import React, { Component } from 'react';
import "./desktop.css";

class Secc_destreza extends Component {
    constructor() {
        super();

        this.state = {
            objetosDestreza: []
        };

        console.log("destreza",this);
    }

    restaurantes = () => {

        let data = {
           modulo:'Destreza' 
        };

        const requestInfo = {
            method: "POST",
            body: JSON.stringify(data),
            header: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
        };
        fetch(this.props.servurlbase + "/getServicios.php", requestInfo)
            .then((response) => response.json())
            .then((objetosDestreza) => {
                    this.setState({ objetosDestreza });                    
            })
            .catch((e) => console.log(e));
    };

    componentDidMount() {
        this.restaurantes();
    }


    render() {

        return (
            <div>
             <div className='container-fluid'>
              <div className='row row_ bg-success'>
                <div className="col-sm-12">
                    <br/>
                    <p className='title_module'>Destreza</p>
                </div>

                {this.state.objetosDestreza.length > 0
                ? this.state.objetosDestreza.map((objetos) => ( 

                   
                    <div className='col-sm-6'style={{padding:'2em'}}>
                        <div className='row'>

                            <div className='col-sm-1'></div>
                            <div className="col-sm-10 rounded-pill borde_div" style={{background: '#ffee88'}}>
                              <div className='row text-center'>

                                <div className='col-sm-12 col-12 text-center'>
                                 <img src={this.props.servurlassets + objetos.img_principal} />
                                </div>
                                <div className='col-sm-12' style={{textAlign:'justify', textAlign:"center",minHeight:"130px"}}>
                                 <p className="fw-bold morado">{objetos.texto}</p>
                                </div>

                              </div>
                            </div>
                            <div className='col-sm-1'></div>


                            <div className='col-sm-1'></div>
                            <div className='col-sm-10'>
                             <img className='rounded-5 borde_div' style={{width:'100%'}} src={this.props.servurlassets + objetos.img} />
                            </div>
                            <div className='col-sm-1'></div>
                        </div>
                       </div>      
        
                ))
                : []}
                <div className='col-sm-12'>
                  <br/><br/><br/><br/><br/>
                </div>
              </div>
             </div>
            </div>
        )
    }

}
export default Secc_destreza