import React, { Component } from 'react';
import "./desktop.css";
import { BrowserRouter as Router, Route, Switch, Link  } from 'react-router-dom';

class Secc_alimentosYbebidas extends Component {
    constructor() {
        super();
        this.state = {
            objetosFood: []
        };
    }

    ayb = () => {
        let data = {
        };

        const requestInfo = {
            method: "POST",
            body: JSON.stringify(data),
            header: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
        };
        fetch(this.props.servurlbase + "/getAlimentosYbebidas.php", requestInfo)
            .then((response) => response.json())
            .then((objetosFood) => {
                if(objetosFood)
               this.setState({objetosFood:objetosFood}); 
            })
            .catch((e) => console.log(e));
    };

    componentDidMount() {
        this.ayb();
    }

    render() {
        return (
            <div>
               <div className='container-fluid'>
                <div className='row row_ bg-danger'>
                    <div className="col-sm-12">
                        <br/>
                     <h2 className='titles'>Alimentos y Bebidas</h2>
                    </div>

                    {this.state.objetosFood.length > 0
                     ? this.state.objetosFood.map((objetos) => ( 
                        <div className='col-sm-4'style={{padding:'2em'}}>
                        <img className='border border-warning' style={{width:'100%'}} src={this.props.servurlassets + objetos.imagen} />
                        </div>
                    ))
                    : []}
                    <div className="col-sm-12 text-center">
                        <Link to='/Restaurantes'>
                            <button type="button" class="btn btn-light fw-bold text-danger" >Ver más, Alimentos y Bebidas..</button>
                        </Link>
                    </div>
                    <div className="col-sm-12">
                        <br/><br/><br/><br/><br/><br/>
                    </div>
                </div>
               </div> 
            </div>
        )
    }

}
export default Secc_alimentosYbebidas