import React, { Component } from 'react';
import "./desktop.css";
import Modal_kboom from './modal_kboom';
import Modal_kreven from './modal_kreven';
import { BrowserRouter as Router, Route, Switch, Link  } from 'react-router-dom';

class Secc_fiestas extends Component {
    constructor() {
        super();
        this.state = {
            modalBoom:false,
            modalReven:false,
            sw_auto:true
        };

    }

    showModalBoom=()=>{
        this.setState({modalBoom:true});
        this.setState({sw_auto:false});
    }

    showModalReven=()=>{
        this.setState({modalReven:true});
        this.setState({sw_auto:false});
    }

    cierra= ()=>{
        this.setState({modalBoom:false});
        this.setState({modalReven:false});
        this.setState({sw_auto:true});
    }

    render() {
        return (
            <div>
               <div className='container-fluid' >
                    <div className='row row_ text-center' style={{ backgroundImage: `url("https://ecommercegd.blob.core.windows.net/imagenes-k/bg-fiestas.png")` }}>
                        <div className="col-sm-4">
                            <br/> 
                            <img className="img-fluid" src={this.props.servurlassets + "festej.png"} />
                        </div>
                        <div className="col-sm-8 fiestas_oscuro">
                            <div className='row'>
                                <div className='col-sm-12 col-12'>
                                    <br/> 
                                    <h3>
                                        ¡Hay una fiesta pensada para ti!
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-11 fiestas_oscuro">
                            <img className="img-fluid" src={this.props.servurlassets + "carrusel.png"} />
                        </div>
                        <div className='col-sm-12'>
                            <img className="img-fluid" src={this.props.servurlassets + "tatoSopla.png"} />
                        </div>
                        <div className='row'>
                            <div className='col-sm-1'></div>
                            <div className='col-sm-4'>
                                <img className="img-fluid" src={this.props.servurlassets + "pachanguita2.png"} />
                            </div>
                            <div className='col-sm-2'></div>
                            <div className='col-sm-4'>
                                <img className="img-fluid" src={this.props.servurlassets + "pachangon2.png"} />
                            </div>
                            <div className='col-sm-1'></div>
                        </div>
                        <div className='row'>
                            <div className="col-sm-4 col-12"></div>
                            <div className="col-sm-4 col-12">
                                <Link to='/Cotiza' style={{cursor:'pointer'}} >
                                    <img style={{height:'50%'}} className="img-fluid" src={this.props.servurlassets + "btnEstrella.png"} />
                                </Link>
                            </div>
                            <div className="col-sm-4 col-12"></div>
                        </div>
                        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                    </div>
                    {
                    this.state.modalBoom === true?
                        <Modal_kboom cierra={this.cierra} servurlassets = {this.props.servurlassets}/>
                    :''
                    }
                    {
                    this.state.modalReven === true?
                        <Modal_kreven cierra={this.cierra} servurlassets = {this.props.servurlassets}/>
                    :''
                    }
               </div>
            </div>
        )
    }

}
export default Secc_fiestas