import React, { Component } from 'react';
import "./desktop.css";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import Swal from 'sweetalert2';
import ReactPixel from 'react-facebook-pixel';
import ModalCompra from './modalCompra';
import { BrowserRouter as Router, Route, Switch, Link  } from 'react-router-dom';

class Secc_precios extends Component {
    constructor() {
        super();

        this.state = {
            objetos: [],
            producto: [],
            modal:false
        };
    }

    carrusel_precios = () => {
        let data = {
            carruselid: 3,
            objetofilterid: "",
        };

        const requestInfo = {
            method: "POST",
            body: JSON.stringify(data),
            header: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
        };
        fetch(this.props.servurlbase + "/getObjetos.php", requestInfo)
            .then((response) => response.json())
            .then((objetos) => {
                    this.setState({ objetos });            
            })
            .catch((e) => console.log(e));
    };

    agregaCarrito2 = (codigo,descripcion,monto) => {
        let data = {
            token: this.props.token,
            descripcion: descripcion,
            codigo: codigo,
            monto: monto
        };

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };

        fetch(this.props.servurlbase + '/addCarrito.php', requestInfo)
            .then(response => response.json())
            .then(respuesta => {
                if (respuesta !== null) {
                    this.props.getCarrito(this.props.token);
                    Swal.fire(
                        'Artículo agregado a carrito',
                        '',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Error',
                        'Upss, algo ocurrió, intente mas tarde',
                        'warning'
                    )
                }
            })
            .catch(e => console.log(e));
    }

    agregaCarrito = (codigo,descripcion,monto,codigoPromocional) => {
        if (codigoPromocional) {
            Swal.fire({
              title: 'Ingrese el código promocional',
              input: 'text',
              inputAttributes: {
                autocapitalize: 'off'
              },
              showCancelButton: true,
              confirmButtonText: 'Verificar',
              showLoaderOnConfirm: true,
              preConfirm: (codigo) => {
                // Aquí puedes comparar el código ingresado con el código promocional
                if (codigo === codigoPromocional) {
                  // Código promocional válido
                  return true; // Indica que la validación fue exitosa
                } else {
                  // Código promocional inválido
                  Swal.showValidationMessage('Código promocional incorrecto');
                  return false; // Indica que la validación falló
                }
              },
              allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              if (result.isConfirmed) {
                this.agregaCarrito2(codigo, descripcion, monto);
              }
            });
          }else{
           this.agregaCarrito2(codigo,descripcion,monto);
        }
        this.changeModal();
    }

    showModalCompra = (producto) =>{
        this.setState({producto});
        this.changeModal();
    }

    changeModal= ()=>{
        this.setState({modal:!this.state.modal});
    }

    componentDidMount() {
        this.carrusel_precios();
        // Configura el píxel de Facebook con tu ID
        // ReactPixel.init('1404238560446929');
        // Rastrea un evento (por ejemplo, PageView)
        // ReactPixel.pageView();
        var x = window.location + "";
        var ruta = x.split('/');
        if(ruta[4]){
            setTimeout(() => {
                const { objetos } = this.state;
                const indice = objetos.findIndex(objeto => objeto.codigo === ruta[4]);
                if (indice !== -1) {
                    this.showModalCompra(this.state.objetos[indice]);
                }
            }, 1000);
        }
    }

    render() {
        const responsive = {
            desktopl: {
                breakpoint: { max: 3000, min: 1440 },
                items: 3,
                slidesToSlide: 1, // optional, default to 1.
            },
            desktops: {
                breakpoint: { max: 1439, min: 1024 },
                items: 3,
                slidesToSlide: 1, // optional, default to 1.
            },
            tablet: {
                breakpoint: { max: 768, min: 464 },
                items: 1,
                slidesToSlide: 1, // optional, default to 1.
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1, // optional, default to 1.
            },
        };

        return (
            <div>
                <div className='container-fluid'>
                    <div className='row row_' style={{ backgroundImage: `url("https://ecommercegd.blob.core.windows.net/imagenes-k/fondo_precios.png")`}}>
                        <div className="col-sm-12 col-12 fiestas">
                            <br/>
                            <h3>Compra en línea y llega a divertirte.</h3>
                            <br/>
                        </div>
                        <div className="col-sm-1 col-12 "></div>
                        <div className="col-sm-10 col-12">
                            <Carousel
                                showDots={false}
                                responsive={responsive}
                                infinite={true}
                                autoPlay={this.state.sw_auto}
                                autoPlaySpeed={2000}
                                keyBoardControl={true}
                                customTransition="all 1s linear"
                                transitionDuration={500}
                                renderDotsOutside={false}
                                arrows={true}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px">
                                    {this.state.objetos.length > 0? this.state.objetos.map((objetos, index) => (
                                        <div className='row'>
                                            <div className='col-sm-1 col-12'></div>  
                                            <div key={index} className='col-sm-10 col-12 text-center'>
                                                <img style={{ width: '100%' ,height:'220px'  }} src={this.props.servurlassets + objetos.nombre + objetos.extension} alt={"img" + objetos.index} />
                                            </div>
                                            <div className='col-sm-1 col-12'></div>  
                                            <div className='col-sm-12 col-12 text-center fw-lighter morado fw-bold' style={{height:'100px',textAlign:'justify'}}>
                                                {objetos.texto_boton}
                                            </div>
                                            <div className='col-sm-12 col-12 text-center'>
                                                <button className='btn btn-danger fw-bold text-light' onClick={this.showModalCompra.bind(this,objetos)}>COMPRAR</button>
                                            </div>
                                        </div>
                                        ))
                                    : []}
                            </Carousel>
                        </div>
                        <div className="col-sm-1 col-12"></div>
                        <div className="col-sm-12 col-12 text-center">
                            <br/>
                            <Link to='/terminosYcondiciones'>
                                <button type="button" class="btn btn-link fw-bold" >Consulta términos y condiciones</button>
                            </Link>
                        </div>
                        <div className="col-sm-12 col-12">
                            <br/><br/><br/><br/><br/><br/><br/>
                        </div>
                    </div>
                </div>
                <ModalCompra agregaCarrito={this.agregaCarrito} servurlassets={this.props.servurlassets} producto={this.state.producto} changeModal={this.changeModal} modal={this.state.modal}/>
            </div>
        )
    }

}
export default Secc_precios