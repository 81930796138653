import React, { Component } from 'react';
import {Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,Row} from 'reactstrap';
import './desktop.css';

class Modal_kboom extends Component{

    constructor() {
        super();
        this.state ={
     }

    }

    render(){
        
        return(
            <div>
                <Modal className='modal-dialog' isOpen={true} toggle={this.props.cierra}>
                    <ModalHeader  className='text-white' style={{ background:'#692894' }}  toggle={this.props.cierra}>
                      <img width={80} src={this.props.servurlassets + "icono_fiesta_boom.png"} />
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                         <Col className="col-sm-12">
                          <img className="img-fluid" src={this.props.servurlassets + "textos_boom_blanco.png"} />
                         </Col>
                        </Row>
                    </ModalBody>
                </Modal>    
            </div>
        );
    }    
}

export default Modal_kboom