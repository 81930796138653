import React, { Component } from "react";
import Cookies from 'universal-cookie';
import { v4 as uuid } from 'uuid';
import "./desktop.css";
import Secc_alimentosYbebidas from "./secc_alimentosYbebidas";
import Secc_kiosco124 from "./secc_kiosco124";
import Secc_contacto from "./secc_contacto";
import Secc_fiestas from "./secc_fiestas";
import Secc_paraDivertirte from "./secc_paraDivertirte";
import Secc_porHacer from "./secc_porHacer";
import Secc_precios from "./secc_precios";
import Secc_inicio from "./secc_inicio";
import Secc_restaurantes from "./secc_restaurantes";
import Secc_kioscos from "./secc_kioscos";
import Secc_servicios from "./secc_servicios";
import Secc_facturacion from "./secc_facturacion";
import Secc_terminosYcondiciones from "./secc_terminosYcondiciones";
import Secc_avisoPrivacidad from "./secc_avisoPrivacidad";
import Secc_preguntas from "./secc_preguntas";
import Secc_bolsaTrabajo from "./secc_bolsaTrabajo";
import Secc_souvenirs from "./secc_souvenirs";
import Secc_destreza from "./secc_destreza";
import Secc_shows from "./secc_shows";
import Secc_cotiza from "./secc_cotiza";
import Secc_cart from "./secc_cart";
import Secc_compraWeb from './secc_compraWeb';
import Secc_Horarios from './Secc_Horarios';
import { URL_CORESERVICE, URL_ASSETS } from "./../config";
import { OffCanvas, OffCanvasMenu, OffCanvasBody } from "react-offcanvas";
import { BrowserRouter as Router, Route, Switch, Link, withRouter } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

class MainDesktop extends Component {
    constructor() {
        super();
        this.state = {
            servurlassets: URL_ASSETS + "/imagenes-k/",
            servurlbase: URL_CORESERVICE,
            codigo: "",
            objetofilterid: "",
            cursecc: "",
            display: "none",
            modal: false,
            sw_auto: true,
            token: '',
            carritoLs: [],
            total: 0,
            textoHorario: "",
            compraWeb: false,
            tokenWeb: ''
        };

    }

    componentWillMount() {
        // sets the initial state
        this.setState({
            isMenuOpened: false
        });
    };

    handleClick() {
        // toggles the menu opened state
        this.setState({ isMenuOpened: !this.state.isMenuOpened });
    };

    showModal = () => {
        this.setState({ modal: true });
        this.setState({ sw_auto: false });
    };

    cierra = () => {
        this.setState({ modal: false });
        this.setState({ sw_auto: true });
    };

    goToken = () => {
        var cookies = new Cookies();
        let token = cookies.get('recoToken');
        let uniquetoken = uuid();
        if (token === undefined) {
            let cookies = new Cookies();
            cookies.set('recoToken', uniquetoken, { path: '/' });
            token = cookies.get('recoToken');
        }
        this.setState({ token: token });
        this.getCarrito(token);
        this.horariosParque();
    };

    getCarrito = (token) => {
        let data = {
            token: token
        }

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };

        fetch(this.state.servurlbase + '/getCarrito.php', requestInfo)
            .then(response => response.json())
            .then(carritoLs => {
                let tot = 0;
                if (carritoLs) {
                    this.setState({ carritoLs });
                    carritoLs.forEach(element => {
                        tot = tot + Number(element.monto);
                    });
                    this.setState({ total: tot });
                }
                else {
                    this.setState({ carritoLs: [] });
                    this.setState({ total: tot });
                }
            })
            .catch(e => console.log(e));
    };

    remplazarToken = () => {
        let cookies = new Cookies();
        let token = cookies.get('recoToken');
        cookies.set('recoToken', undefined, { path: '/' });

        let uniquetoken = uuid();
        cookies = new Cookies();
        cookies.set('recoToken', uniquetoken, { path: '/' });
        token = cookies.get('recoToken');
    };

    horariosParque = () => {

        let data = {
        }

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };

        fetch(this.state.servurlbase + '/getHorarios.php', requestInfo)
            .then(response => response.json())
            .then(response => {

                if (response) {

                    this.setState({ textoHorario: response[0].texto });

                }
                else {
                }
            })
            .catch(e => console.log(e));

    };

    componentDidMount() {
        this.goToken();
        var x = window.location + "";
        var ruta = x.split('/');
        if (ruta[3] === '#compraWeb') {
            this.props.history.push('/CompraWeb/' + ruta[4]);
        }
        if (ruta[3] === '#compra') {
            //this.props.history.push('/compra/' + ruta[4]);
            this.setState({ cursecc: 'compra' });
        }
        if (ruta[3] === '#SportsBar') {
            //this.props.history.push('/SportsBar/');
            this.setState({ cursecc: 'SportsBar' });
        }
        if (ruta[3] === '#Kiosco124') {
            //this.props.history.push('/Kiosco124/');
            this.setState({ cursecc: 'Kiosco124' });
        }

        ReactPixel.init('1404238560446929');
        ReactPixel.pageView();
    }

    render() {
        const responsive = {
            desktopl: {
                breakpoint: { max: 3000, min: 1440 },
                items: 1,
                slidesToSlide: 1, // optional, default to 1.
            },
            desktops: {
                breakpoint: { max: 1439, min: 1024 },
                items: 1,
                slidesToSlide: 1, // optional, default to 1.
            },
            tablet: {
                breakpoint: { max: 768, min: 464 },
                items: 1,
                slidesToSlide: 4, // optional, default to 1.
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1, // optional, default to 1.
            },
        };
        const { cursecc } = this.state
        return (
            <div>
                <Router>
                    {/* ------------------------------- NAV SECCION -------------------------------------*/}
                    <nav className="navbar fixed-top navbar-expand-xl bg-light borde_div" style={{ height: '85px' }}>
                        <div className="container-fluid">
                            <a className="navbar-brand" href="/">
                                <Link to="/">
                                    <img width={200} className="img-fluid" src={this.state.servurlassets + "logo.png"} alt="logo" />
                                </Link>
                            </a>
                            <img className="navbar-toggler" style={{ width: '45px', cursor: 'pointer' }} src={this.state.servurlassets + "menus.png"} onClick={this.handleClick.bind(this)} />
                            <div className="collapse navbar-collapse">
                                <ul className="navbar-nav m-auto">
                                    <li className="nav-item" key="uniqueId1">
                                        <Link to='/Horarios' className="nav-link active" >
                                            <p className="fw-bold morado">Horarios</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item" key="uniqueId1">
                                        <Link to='/Compra' className="nav-link active" >
                                            <p className="fw-bold morado">Precios y compra en línea</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item" key="uniqueId2">
                                        <Link to='/Atracciones' className="nav-link active" >
                                            <p className="fw-bold morado">Cosas para hacer</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item" key="uniqueId3">
                                        <Link to='/SportsBar' className="nav-link active" >
                                            <p className="fw-bold morado">Alimentos y bebidas</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item" key="uniqueId4">
                                        <Link to='/FiestasK' className="nav-link active" >
                                            <p className="fw-bold morado">Fiestas</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item" key="uniqueId5">
                                        <Link to='/MapaK' className="nav-link active" >
                                            <p className="fw-bold morado">Para divertirte</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item mb-0" key="uniqueId6">
                                        <Link to='/Contacto' className="nav-link active" >
                                            <p className="fw-bold morado">Contacto</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item d-none d-lg-block" key="uniqueId7">
                                        <Link to='/Cart' className="cart">
                                            <img style={{ width: '40px' }} src={this.state.servurlassets + "shopping.svg"} />
                                            <span id="cart_menu_num" data-action="cart-can" className="badge rounded-circle">{this.state.carritoLs.length}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>

                    <OffCanvas
                        width={300}
                        transitionDuration={300}
                        effect={"parallax"}
                        isMenuOpened={this.state.isMenuOpened}
                        position={"right"}>
                        <OffCanvasBody>
                        </OffCanvasBody>
                        <OffCanvasMenu className="bg-light menu_movil">
                            <ul className="list-group list-group-flush">
                                <br /><br /><br /><br />
                                <li key="uniqueId8" className="list-group-item">
                                    <button onClick={this.handleClick.bind(this)} type="button" className="btn-close" aria-label="Close"></button>
                                </li>
                                <li key="uniqueId7" className="list-group-item text-black" >
                                    <p className="fw-bold morado" onClick={this.handleClick.bind(this)}>
                                        <Link to='/Horarios' className="nav-link active">Horarios</Link>
                                    </p>
                                </li>
                                <li key="uniqueId9" className="list-group-item text-black" >
                                    <p className="fw-bold morado" onClick={this.handleClick.bind(this)}>
                                        <Link to='/Compra' className="nav-link active">Precios y compra en línea</Link>
                                    </p>
                                </li>
                                <li key="uniqueId10" className="list-group-item text-black">
                                    <p className="fw-bold morado" onClick={this.handleClick.bind(this)}>
                                        <Link to='/Atracciones' className="nav-link active">Cosas para hacer</Link>
                                    </p>
                                </li>
                                <li key="uniqueId11" className="list-group-item text-black">
                                    <p className="fw-bold morado" onClick={this.handleClick.bind(this)}>
                                        <Link to='/SportsBar' className="nav-link active">Alimentos y bebidas</Link>
                                    </p>
                                </li>
                                <li key="uniqueId12" className="list-group-item text-black">
                                    <p className="fw-bold morado" onClick={this.handleClick.bind(this)}>
                                        <Link to='/FiestasK' className="nav-link active">Fiestas</Link>
                                    </p>
                                </li>
                                <li key="uniqueId13" className="list-group-item text-black">
                                    <p className="fw-bold morado" onClick={this.handleClick.bind(this)}>
                                        <Link to='/MapaK' className="nav-link active">Para divertirte</Link>
                                    </p>
                                </li>
                                <li key="uniqueId14" className="list-group-item text-black">
                                    <p className="fw-bold morado" onClick={this.handleClick.bind(this)}>
                                        <Link to='/Contacto' className="nav-link active">Contacto</Link>
                                    </p>
                                </li>
                                <li key="uniqueId15" className="list-group-item text-black">
                                    <Link to='/Cart' className="cart" >
                                        <img style={{ width: '40px' }} src={this.state.servurlassets + "shopping.svg"} />
                                        <span id="cart_menu_num" data-action="cart-can" className="badge rounded-circle">
                                            {this.state.carritoLs.length}
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </OffCanvasMenu>
                    </OffCanvas>

                    {/* ------------------------------- SECCIONES -------------------------------------*/}
                    <div style={{ paddingTop: '6%' }}>
                        <Switch>
                            <Route path="/SportsBar">
                                <Secc_alimentosYbebidas servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} />
                            </Route>
                            <Route path="/Compra">
                                <Secc_precios responsive={responsive} servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} token={this.state.token} getCarrito={this.getCarrito} />
                            </Route>
                            <Route path="/Kiosco124">
                                <Secc_kiosco124 servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} />
                            </Route>
                            <Route path="/Contacto">
                                <Secc_contacto servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} />
                            </Route>
                            <Route path="/FiestasK">
                                <Secc_fiestas servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} />
                            </Route>
                            <Route path="/MapaK">
                                <Secc_paraDivertirte servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} cambiaSeccion={this.cambiaSeccion} />
                            </Route>
                            <Route path="/Atracciones">
                                <Secc_porHacer servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} />
                            </Route>
                            <Route path="/Restaurantes">
                                <Secc_restaurantes servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} />
                            </Route>
                            <Route path="/Kioscos">
                                <Secc_kioscos servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} />
                            </Route>
                            <Route path="/Servicios">
                                <Secc_servicios servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} />
                            </Route>
                            <Route path="/Facturacion">
                                <Secc_facturacion servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} />
                            </Route>
                            <Route path="/terminosYcondiciones">
                                <Secc_terminosYcondiciones servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} />
                            </Route>
                            <Route path="/AvisoPrivacidad">
                                <Secc_avisoPrivacidad servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} />
                            </Route>
                            <Route path="/Preguntas">
                                <Secc_preguntas servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} />
                            </Route>
                            <Route path="/BolsaTrabajo">
                                <Secc_bolsaTrabajo servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} />
                            </Route>
                            <Route path="/Souvenirs">
                                <Secc_souvenirs servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} />
                            </Route>
                            <Route path="/Destreza">
                                <Secc_destreza servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} />
                            </Route>
                            <Route path="/Shows">
                                <Secc_shows servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} />
                            </Route>
                            <Route path="/Cotiza">
                                <Secc_cotiza servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} />
                            </Route>
                            <Route path="/CompraWeb">
                                <Secc_compraWeb remplazarToken={this.remplazarToken} servurlbase={this.state.servurlbase} tokenCompra={this.state.tokenCompra} />
                            </Route>
                            <Route path="/Horarios">
                                <Secc_Horarios servurlassets={this.state.servurlassets}/>
                            </Route>
                            <Route path="/compraQR">
                                <Secc_precios responsive={responsive} servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} token={this.state.token} getCarrito={this.getCarrito} />
                            </Route>
                            <Route path="/Cart">
                                <Secc_cart servurlassets={this.state.servurlassets} cierra={this.cierra} servurlbase={this.state.servurlbase} token={this.state.token} remplazarToken={this.remplazarToken} getCarrito={this.getCarrito} carritoLs={this.state.carritoLs} total={this.state.total} />
                            </Route>
                            {cursecc === 'compra' ?
                                <Route exact path="/">
                                    <Secc_precios responsive={responsive} servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} token={this.state.token} getCarrito={this.getCarrito} />
                                </Route>
                            :cursecc === 'SportsBar' ?
                                <Route exact path="/">
                                    <Secc_alimentosYbebidas responsive={responsive} servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} token={this.state.token} getCarrito={this.getCarrito} />
                                </Route>
                            :cursecc === 'Kiosco124' ?
                                <Route exact path="/">
                                    <Secc_kiosco124 responsive={responsive} servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} token={this.state.token} getCarrito={this.getCarrito} />
                                </Route>
                            :
                                <Route exact path="/">
                                    <Secc_inicio responsive={responsive} servurlassets={this.state.servurlassets} servurlbase={this.state.servurlbase} cambiaSeccion={this.cambiaSeccion} />
                                </Route>
                            }
                        </Switch>
                    </div>

                    {/* ------------------------------- BAR SOCIAL -------------------------------------*/}
                    <div className="sidebarSocial desapareceimagen">
                        <div className="social-icons-container">
                            <a href="https://www.facebook.com/kataplumoficial/" className="a-link" target="_blank">
                                <img style={{ width: 40, height: 40 }} src={this.state.servurlassets + 'facebook.png'} alt="Alternate Text" />
                            </a>
                            <a href="https://twitter.com/KataplumOficial" className="a-link" target="_blank">
                                <img style={{ width: 40, height: 40 }} src={this.state.servurlassets + 'gorjeo.png'} alt="Alternate Text" />
                            </a>
                            <a href="https://www.instagram.com/kataplumoficial/" className="a-link" target="_blank">
                                <img style={{ width: 40, height: 40 }} src={this.state.servurlassets + 'instagram.png'} alt="Alternate Text" />
                            </a>
                            <a href="https://www.youtube.com/channel/UCJwzyMiZ48gbLb52iT6mwnQ" className="a-link" target="_blank">
                                <img style={{ width: 40, height: 40 }} src={this.state.servurlassets + 'youtube.png'} alt="Alternate Text" />
                            </a>
                        </div>
                    </div>

                    {/* ------------------------------- FOOTER FIXED SECCION -------------------------------------*/}
                    <div className="container-fluid footer-bottom-fixed">
                        <div className="row">
                            <div className="col-sm-12 col-12">
                                <a href="https://www.google.com/maps/place/%C2%A1Kataplum!/@19.314327,-99.0770666,17z/data=!3m1!5s0x85ce024107e2ffcb:0x983a4e62d81a603b!4m14!1m7!3m6!1s0x85ce03d4b49f40db:0xf1f408281eef55e7!2sParque+Las+Antenas!8m2!3d19.314327!4d-99.0770666!16s%2Fg%2F11sgdtwbx6!3m5!1s0x85ce0241374c639d:0x9afe9e1d4b6ae56d!8m2!3d19.3138075!4d-99.0762449!16s%2Fg%2F11hbnkp3w1?entry=ttu" 
                                target="_blank" rel="noopener noreferrer" className="fw-bold morado">
                                    &nbsp;<img width={14} src={this.state.servurlassets + "loaction.svg"} alt="Alternate Text" className="footer-atencion" />
                                    &nbsp;Centro Comercial Las Antenas, Av. Canal de Garay 3278, La Esperanza, CDMX.
                                </a>
                            </div>
                        </div>
                    </div>

                </Router>
            </div>
        );
    }
}
export default withRouter(MainDesktop);
