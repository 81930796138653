import React, { Component } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import 'bootstrap/dist/css/bootstrap.css';
import ModalTerminosCondiciones from './modalTerminosCondiciones';

class Secc_terminosYcondiciones extends Component {
    constructor() {
        super();
        this.state = {
            objetosLs: [],
            servurlassets: "https://ecommercegd.blob.core.windows.net" + "/imagenes-k/",
            modal:false,
            sw_auto:true,
            id:0,
            secciones:[],
            tyc:[]
        };

    }

    showModal=(valor)=>{
        this.setState({modal:true});
        this.setState({sw_auto:false});
        this.setState({id:valor});
    }

    cierra= ()=>{
    this.setState({modal:false});
    this.setState({sw_auto:true});
    }

    getTyC = (seccion) => {
        let data = {
            seccion
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.props.servurlbase + '/getTerminosycondiciones.php', requestInfo)
        .then(response => response.json())
        .then(response => {
            if(response){
                this.setState({tyc:response});
            }
        }).catch(e => console.log(e));
    }

    getSecciones = () => {
        const requestInfo = {
            method: 'POST',
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.props.servurlbase + '/getSeccionesAgrupa.php', requestInfo)
        .then(response => response.json())
        .then(response => {
            if(response){
                this.setState({secciones:response});
            }
        }).catch(e => console.log(e));
    }

    componentDidMount(){
        this.getSecciones();
        this.getTyC('Kataportes');
    }

    render() {
        return (
            <div>
                <div className='container-fluid'>
                    <div className='row row_ bg-warning' style={{padding:'2em'}}>
                        <div className="col-sm-12 fiestas">
                            <h3>Términos y condiciones</h3>
                        </div>
                        {this.state.secciones.length>0?this.state.secciones.map((seccion) => (  
                            <div className="col-sm-2 col-12 text-center">
                                <h4 className='fw-bold text-danger' style={{cursor:'pointer'}} onClick={this.getTyC.bind(this,seccion.seccion)}>{seccion.seccion}</h4>
                            </div>
                        )): []}
                        <div className="col-sm-12">
                            <Collapse in={true}>
                                <div className="card card-body">
                                    <ul className="list-group">
                                        {this.state.tyc.length>0?this.state.tyc.map((tyc) => ( 
                                            <li className="list-group-item"><h5 className='fw-bold morado' style={{cursor:'pointer'}} onClick={this.showModal.bind(this,tyc.id)}>{tyc.titulo}</h5></li>
                                        )): []}                
                                    </ul>
                                </div>
                            </Collapse>
                        </div>
                        <div className='col-sm-12'>
                        <br/><br/><br/><br/><br/>
                        </div>  
                    </div>
                    {
                        this.state.modal === true?
                            <ModalTerminosCondiciones cierra={this.cierra} servurlbase = {this.props.servurlbase} id = {this.state.id}/>
                        :''
                    }
                </div>
            </div>
        )
    }
}export default Secc_terminosYcondiciones