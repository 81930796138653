import React, { Component } from 'react';
import "./desktop.css";

class Secc_kiosco124 extends Component {

    render() {
        return (
            <div>
               <div className='container-fluid'>
                <div className='row row_ bg-info'>
                    <div className="col-sm-12">
                        <br/>
                        <h2 className='titles'>Monchis</h2>
                    </div>
                    <div className='col-sm-4'style={{padding:'2em'}}>
                        <img className='border border-warning' style={{width:'100%'}} src={this.props.servurlassets+'ks4_frente.jpg' } />
                    </div>
                    <div className='col-sm-4'style={{padding:'2em'}}>
                        <img className='border border-warning' style={{width:'100%'}} src={this.props.servurlassets+'ks4_qr.jpg' } />
                    </div>
                    <div className='col-sm-4'style={{padding:'2em'}}>
                        <img className='border border-warning' style={{width:'100%'}} src={this.props.servurlassets+'promo_cerveza.png' } />
                    </div>
                </div>
               </div> 
            </div>
        )
    }

}
export default Secc_kiosco124