import React, { Component } from "react";
import "./desktop.css";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { BrowserRouter as Router, Route, Switch, Link  } from 'react-router-dom';

class Secc_inicio extends Component {
    constructor() {
        super();

        this.state = {
            servurlassets: "https://ecommercegd.blob.core.windows.net" + "/imagenes-k/",
            sw_auto: true,
            objetosLs: [],
            objetosShows: [],
        };
    }

    carrusel_uno = () => {
        let data = {
            carruselid: 1,
            objetofilterid: "",
        };
        const requestInfo = {
            method: "POST",
            body: JSON.stringify(data),
            header: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
        };
        fetch(this.props.servurlbase + "/getObjetos.php", requestInfo)
            .then((response) => response.json())
            .then((objetosLs) => {
                if (objetosLs !== null) {
                    this.setState({ objetosLs });
                    if (this.props.codigo !== "") {
                        for (let i = 0; i < objetosLs.length; i++) {
                            if (objetosLs[i].codigo === this.props.codigo) {
                                this.showModal();
                                break;
                            }
                        }
                    }
                } else {
                    this.setState({ objetosLs: [] });
                }
            })
            .catch((e) => console.log(e));
    };

    carrusel_shows = () => {
        let data = {
            carruselid: 2,
            objetofilterid: "",
        };
        const requestInfo = {
            method: "POST",
            body: JSON.stringify(data),
            header: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
        };
        fetch(this.props.servurlbase + "/getObjetos.php", requestInfo)
            .then((response) => response.json())
            .then((objetosShows) => {
                if (objetosShows !== null) {
                    this.setState({ objetosShows });
                    if (this.props.codigo !== "") {
                        for (let i = 0; i < objetosShows.length; i++) {
                            if (objetosShows[i].codigo === this.props.codigo) {
                                this.showModal();
                                break;
                            }
                        }
                    }
                } else {
                    this.setState({ objetosShows: [] });
                }
            })
            .catch((e) => console.log(e));
    };

    componentDidMount() {
        this.carrusel_uno();
        this.carrusel_shows();
    }

    render() {
        
        return (
            <div>
                {/* ------------------------------- PRIMERA SECCION -------------------------------------*/}
                <div className="container-fluid">
                    <div className="row slide-wrap" style={{ backgroundImage: `url("https://ecommercegd.blob.core.windows.net/imagenes-k/Back_Kataplum01.png")` }}>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-8">
                            <Carousel
                                showDots={false}
                                responsive={this.props.responsive}
                                infinite={true}
                                autoPlay={this.state.sw_auto}
                                autoPlaySpeed={6000}
                                keyBoardControl={true}
                                customTransition="all 1s linear"
                                transitionDuration={500}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                renderDotsOutside={false}
                                arrows={true}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                            >
                                {this.state.objetosLs.length > 0
                                    ? this.state.objetosLs.map((objetos, index) => (
                                          <div key={index}>
                                              <img className="img-fluid" src={this.state.servurlassets + objetos.nombre + objetos.extension} alt={"img" + objetos.index} />
                                          </div>
                                      ))
                                    : []}
                            </Carousel>
                        </div>
                        <div className="col-sm-2"></div>
                    </div>
                </div>

                {/* ------------------------------- SEGUNDA SECCION -------------------------------------*/}

                <div className="container-fluid">
                    <div className="row cosas-para-hacer" style={{ backgroundImage: `url("https://ecommercegd.blob.core.windows.net/imagenes-k/nubes.jpg")` }}>
                        <div className="col-sm-12 text-align">
                            <h2>Cosas por hacer</h2>
                        </div>
                        <div className="col-sm-12 text-align">
                            <p className="blu-clr">Experimenta un mundo donde la realidad no es aburrida, lo imposible es posible y la magia no tiene límites.</p>
                            <br />
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6  col-6">
                            <div className="cosas-para-card">
                                <Link to='/Atracciones'>
                                    <img src={this.state.servurlassets + "cosas-img-1.png"} />
                                    <button className="btn btn-primary" type="button" >
                                        Atracciones
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6  col-6">
                            <div className="cosas-para-card">
                                <Link to='/Kioscos'>
                                    <img src={this.state.servurlassets + "cosas-img-2.png"} />
                                    <button className="btn btn-primary" type="button" >
                                        Kioscos
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6  col-6">
                            <div className="cosas-para-card">
                                <Link to='/Restaurantes'>
                                    <img src={this.state.servurlassets + "cosas-img-4.png"} />
                                    <button className="btn btn-primary" type="button">
                                        Restaurantes
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6  col-6">
                            <div className="cosas-para-card">
                                <Link to='/Souvenirs'>
                                    <img src={this.state.servurlassets + "cosas-img-5.png"} />
                                    <button className="btn btn-primary" type="button" >
                                        Tiendas de souvenir
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6  col-6">
                            <div className="cosas-para-card">
                                <Link to='/Shows'>
                                    <img src={this.state.servurlassets + "cosas-img-6.png"} />
                                    <button className="btn btn-primary" type="button" >
                                        Shows
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6  col-6">
                            <div className="cosas-para-card">
                                <Link to='/Atracciones'>
                                    <img src={this.state.servurlassets + "cosas-img-7.png"} />
                                    <button className="btn btn-primary" type="button" >
                                        Cinemas 6D
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6  col-6">
                            <div className="cosas-para-card">
                                <Link to='/Destreza'>
                                    <img src={this.state.servurlassets + "cosas-img-8.png"} />
                                    <button className="btn btn-primary" type="button" >
                                        Área de destreza
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6  col-6">
                            <div className="cosas-para-card">
                                <Link to='/Servicios'>
                                    <img src={this.state.servurlassets + "ZooRider_500x500.jpg"} />
                                    <button className="btn btn-primary" type="button" >
                                        Servicios
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ------------------------------- TERCERA SECCION -------------------------------------*/}

                <div className="container-fluid">
                    <div className="row experiencias-wraper" style={{ backgroundImage: `url("https://ecommercegd.blob.core.windows.net/imagenes-k/Clouds03.jpg")` }}>
                        <div className="col-sm-12">
                            <h3>
                                Experiencias <span>#Kataplum</span>
                            </h3>
                            <br />
                        </div>
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6">
                            <a href="https://www.instagram.com/kataplumoficial/">
                                <img style={{ width: "100%" }} src={this.state.servurlassets + "group-img.png"} />
                            </a>
                        </div>
                        <div className="col-sm-3"></div>
                    </div>
                </div>

                {/* ------------------------------- CUARTA SECCION -------------------------------------*/}

                <div className="container-fluid">
                    <div className="row shows-al-aire-libre" style={{ backgroundImage: `url("https://ecommercegd.blob.core.windows.net/imagenes-k/bg-4.png")` }}>
                        <div className="col-sm-12">
                            <h3>Shows al aire libre</h3>
                        </div>
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6">
                            <Carousel
                                showDots={false}
                                responsive={this.props.responsive}
                                infinite={true}
                                autoPlay={this.state.sw_auto}
                                autoPlaySpeed={2000}
                                keyBoardControl={true}
                                customTransition="all 1s linear"
                                transitionDuration={500}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                renderDotsOutside={false}
                                arrows={true}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                            >
                                {this.state.objetosShows.length > 0
                                    ? this.state.objetosShows.map((objetos, index) => (
                                          <div key={index}>
                                              <center>
                                                  <img className="img-fluid" src={this.state.servurlassets + objetos.nombre + objetos.extension} alt={"img" + objetos.index} />
                                              </center>
                                          </div>
                                      ))
                                    : []}
                            </Carousel>
                        </div>
                        <div className="col-sm-3"></div>
                    </div>
                </div>

                {/* ------------------------------- QUINTA SECCION -------------------------------------*/}

                <div className="container-fluid">
                    <div className="row fiestas-inner" style={{ backgroundImage: `url("https://ecommercegd.blob.core.windows.net/imagenes-k/bg-5.png")` }}>
                        <div className="col-sm-12 text-center fiestas_oscuro">
                            <h3>¡Tu fiesta será mágica en Kataplum!</h3>
                        </div>

                        <div className="col-sm-1"></div>
                        <div className="col-sm-10 col-12 text-center fiestas_oscuro">
                            <h5>
                                Pasa un cumpleaños inolvidable con los diferentes paquetes, horarios, dinámicas, juegos y emoción en salones amplios para que lleves a tus invitados, puedas partir el pastel, comer tus alimentos y llevarte a
                                un mundo de sueños.
                            </h5>
                        </div>
                        <div className="col-sm-1"></div>

                        <div className="col-sm-1"></div>
                        <div className="col-sm-10 text-center">
                            <img className="img-fluid" src={this.state.servurlassets + "festej.png"} />
                        </div>
                        <div className="col-sm-1"></div>
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10 text-center">
                            <div className="row">
                                <div className="col-sm-4 col-12">
                                    <br />
                                    <a className="btn btn-light btn-lg" href="tel:800 881 8811" target="_blank">
                                        <img width={25} className="img-fluid" src={this.state.servurlassets + "phone.svg"} />
                                        <span className='fw-bold morado'> 800 881 8811</span>
                                    </a>
                                </div>
                                <div className="col-sm-4 col-12">
                                    <br />
                                    <a className="btn btn-light btn-lg" href="https://api.whatsapp.com/send/?phone=%2B525526979865&text=%C2%A1Hola%21+Solicito+informaci%C3%B3n+de+fiestas..&app_absent=0" target="_blank">
                                        <img width={30} className="img-fluid" src={this.state.servurlassets + "whattsapp.svg"} />
                                        <span className='fw-bold morado'> 552 697 98 65</span>
                                    </a>
                                </div>
                                <div className="col-sm-4 col-12">
                                    <br />
                                    <Link to='Cotiza' className="btn btn-light btn-lg" >
                                        <span className='fw-bold morado'>Cotiza tu fiesta aquí</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>
                </div>

                {/* ------------------------------- FOOTER SECCION -------------------------------------*/}

                <footer style={{ padding: 10 }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-2 col-12 text-center" style={{ cursor: "pointer" }}>
                                <Link to='/Contacto' className="text-decoration-underline text-white" >Contacto</Link>
                            </div>
                            <div className="col-sm-2 col-12 text-center" style={{ cursor: "pointer" }}>
                                <Link to='/Facturacion'className="text-decoration-underline text-white" >Facturación electrónica</Link>
                            </div>
                            <div className="col-sm-2 col-12 text-center" style={{ cursor: "pointer" }}>
                                <Link to='/TerminosYcondiciones'className="text-decoration-underline text-white" >Términos y condiciones</Link>
                            </div>
                            <div className="col-sm-2 col-12 text-center" style={{ cursor: "pointer" }}>
                                <Link to='/AvisoPrivacidad' className="text-decoration-underline text-white" >Aviso de privacidad</Link>
                            </div>
                            <div className="col-sm-2 col-12 text-center" style={{ cursor: "pointer" }}>
                                <Link to='/BolsaTrabajo' className="text-decoration-underline text-white" >Bolsa de trabajo</Link>
                            </div>
                            <div className="col-sm-2 col-12 text-center"style={{ cursor: "pointer" }}>
                                <Link to='/Preguntas' className="text-decoration-underline text-white" >Preguntas frecuentes</Link>
                            </div>
                            <div className="col-sm-12 col-12">
                                <br/>
                            </div>
                            <div className="col-sm-2 col-12"></div>
                            <div className="col-sm-1 col-6 text-center">
                                <a>
                                    <img className="img-fluid" src={this.state.servurlassets + "Grupo_909.png"} alt="clinet"></img>
                                </a>
                            </div>
                            <div className="col-sm-1 col-6 text-center">
                                <a>
                                    <img className="img-fluid" src={this.state.servurlassets + "2.png"} alt="clinet"></img>
                                </a>
                            </div>
                            <div className="col-sm-1 col-6 text-center">
                                <a>
                                    <img className="img-fluid" src={this.state.servurlassets + "3.png"} alt="clinet"></img>
                                </a>
                            </div>
                            <div className="col-sm-1 col-6 text-center">
                                <a>
                                    <img className="img-fluid" src={this.state.servurlassets + "4.png"} alt="clinet"></img>
                                </a>
                            </div>
                            <div className="col-sm-1 col-6 text-center">
                                <a>
                                    <img className="img-fluid" src={this.state.servurlassets + "5.png"} alt="clinet"></img>
                                </a>
                            </div>
                            <div className="col-sm-1 col-6 text-center">
                                <a>
                                    <img className="img-fluid" src={this.state.servurlassets + "6.png"} alt="clinet"></img>
                                </a>
                            </div>
                            <div className="col-sm-1 col-6 text-center">
                                <a>
                                    <img className="img-fluid" src={this.state.servurlassets + "8.png"} alt="clinet"></img>
                                </a>
                            </div>
                            <div className="col-sm-1 col-6 text-center">
                                <a>
                                    <img className="img-fluid" src={this.state.servurlassets + "9.png"} alt="clinet"></img>
                                </a>
                            </div>
                            <div className="col-sm-2 col-12"></div>
                            <div className="col-sm-12 col-12">
                                <p className="fw-bold text-center text-white">¡Kataplum! Todos los Derechos Reservados, 2023. México.</p>
                            </div>
                            <div className="col-md-12 col-sm-12 col-12">
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}
export default Secc_inicio;
