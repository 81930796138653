import React, { Component } from 'react';
import "./desktop.css";

class Secc_porHacer extends Component {
    constructor() {
        super();

        this.state = {
            objetosAtracciones: []
        };
    }

    componentDidMount() {
    }

    atracciones = () => {
        let data = {
        };

        const requestInfo = {
            method: "POST",
            body: JSON.stringify(data),
            header: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
        };
        fetch(this.props.servurlbase + "/getAtracciones.php", requestInfo)
            .then((response) => response.json())
            .then((objetosAtracciones) => {
                    this.setState({ objetosAtracciones });
            })
            .catch((e) => console.log(e));
    };

    componentDidMount() {
        this.atracciones();
    }

    render() {

        return (
            <div>
             <div className='container-fluid'>
              <div className='row row_ bg-success'>
                <div className="col-sm-12 fiestas">
                    <br/>
                    <p className='title_module'>Atracciones</p>
                </div>

                {this.state.objetosAtracciones.length > 0
                ? this.state.objetosAtracciones.map((objetos) => ( 

                      <div className='col-sm-6'style={{padding:'2em'}}>
                        <div className='row'>
                            <div className='col-sm-1'></div>
                            <div className="col-sm-10 rounded-5 borde_div" style={{background: '#ffee88'}}>
                                <div className='row'>
                               
                                 <div className='col-sm-12 col-12 text-center'>
                                  <img src={this.props.servurlassets + objetos.img_principal} />
                                 </div>
                                
                         
                                 <div className='col-sm-12' style={{textAlign:'justify', minHeight:"auto",paddingBottom:'10%'}}>
                                  <p className="fs-6 morado">{objetos.texto}</p>
                                 </div>

                                 <div className='col-sm-6 col-6 text-center'>
                                  <img style={{height:'30px'}} src={this.props.servurlassets + objetos.tipo_emocion} />
                                  <p className="fw-bold">{objetos.emocion}</p>
                                  <p className="fs-6 morado">{objetos.emociones_text}</p>
                                 </div>

                                 <div className='col-sm-6 col-6 text-center'>
                                  <img style={{height:'30px'}} src={this.props.servurlassets + 'kataporte.png'} />
                                  <p className="fw-bold">Kataporte</p>
                                  {objetos.kataporte === '1'? 
                                    <p className="fs-6 morado">Incluido en tu Kataporte</p>
                                    :<p className="fs-6 morado">No Incluido en tu Kataporte</p>
                                  }
                                  
                                 </div>

                                 <div className='col-sm-4 col-4 text-center' style={{textAlign:'justify', minHeight:'135px'}}>
                                  <img style={{height:'30px'}} src={this.props.servurlassets + 'altra1.png'} />
                                  <p className="fw-bold">Altura mínima:</p>
                                  <p className="fs-6 morado">{objetos.altura_min}</p>
                                 </div>
                                 <div className='col-sm-4 col-4 text-center' style={{textAlign:'justify', minHeight:'135px'}}>
                                  <img style={{height:'30px'}} src={this.props.servurlassets + 'altra2.png'} />
                                  <p className="fw-bold">Altura máxima:</p>
                                  <p className="fs-6 morado">{objetos.altura_max}</p>
                                 </div>
                                 <div className='col-sm-4 col-4 text-center' style={{textAlign:'justify', minHeight:'135px'}}>
                                  <img style={{height:'30px'}} src={this.props.servurlassets + 'tag.png'} />
                                  <p className="fw-bold">Categoría:</p>
                                  <p className="fs-6 morado">{objetos.categoria}</p>
                                 </div>
                                </div>
                            </div>
                            <div className='col-sm-1'></div>
                            <div className='col-sm-12 text-center'>
                             <img className='rounded-5 borde_div' style={{width:'100%'}} src={this.props.servurlassets + objetos.img} />
                            </div>
                        </div>
                      </div>     
        
                ))
                : []}
                <div className='col-sm-12'>
                  <br/><br/><br/><br/><br/>
                </div>
              </div>
             </div>
            </div>
        )
    }

}
export default Secc_porHacer