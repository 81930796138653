import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";

class Secc_preguntas extends Component {
    constructor() {
        super();

        this.state = {
            objetosPreguntas: [],
        };
    }

    preguntas = () => {
        let data = {};

        const requestInfo = {
            method: "POST",
            body: JSON.stringify(data),
            header: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
        };
        fetch(this.props.servurlbase + "/getPreguntasFrecuentes.php", requestInfo)
            .then((response) => response.json())
            .then((objetosPreguntas) => {
                if (objetosPreguntas !== null) {
                    this.setState({ objetosPreguntas });
                }
            })
            .catch((e) => console.log(e));
    };

    componentDidMount() {
        this.preguntas();
    }

    render() {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row row_ bg-danger p-2 text-dark bg-opacity-50">
                        <div className="col-sm-12 fiestas">
                            <h3>Preguntas frecuentes</h3>
                        </div>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-8" style={{textAlign:'justify'}}>
                          <img width={170} src={this.props.servurlassets + "job.png"} alt="clinet"></img>
                        <Accordion defaultActiveKey="none">
                            {this.state.objetosPreguntas.length > 0
                                ? this.state.objetosPreguntas.map((objetos) => (
                                      <Accordion.Item eventKey={""+objetos.id}>
                                          <Accordion.Header>
                                          <p className='fw-lighter'>{objetos.pregunta}</p>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                          <p className='fw-lighter'>{objetos.respuesta}</p>
                                          </Accordion.Body>
                                      </Accordion.Item>
                                  ))
                                : []}
                        </Accordion>
                        </div>
                        <div className="col-sm-2"></div>
                        <div className='col-sm-12'>
                        <br/><br/><br/><br/><br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Secc_preguntas;
