import React, { Component } from 'react';

class Secc_facturacion extends Component {
    constructor() {
        super();

        this.state = {
            objetosLs: [],
            servurlassets: "https://ecommercegd.blob.core.windows.net" + "/imagenes-k/"
        };
    }

    render() {
        return (
            <div>
             <div className='container-fluid'>
              <div className='row row_' style={{ background:'#9B7EB8' }}>
              <div className="col-sm-12 fiestas">
                <br/>
               <h3>¡Te enviamos tu factura al momento!</h3>
              </div>
               <div className="col-sm-8">
                <br/>
                <p className="fw-bold text-white">Para obtener la factura de tus compras en Kataplum, sigue unos sencillos pasos:<br/></p>
                <p className="fw-lighter text-white">
                 -Haz click en “Facturación electrónica”<br/>
                 -Escribe el número de Fólio de tu ticket de compra, el monto total de tu comprobante y tu RFC…<br/>
                 -Y listo, obtén tu factura al momento.<br/>
                  En un plazo máximo de 48 horas hábiles, te estaremos mandando tu factura a la misma dirección de correo electrónico.<br/>
                 *Para poder facturar es necesario que tu consumo haya sido en el mes actual.<br/>
                 Recuerda que el envío de la factura por correo electrónico no tiene ningún costo.<br/>
                </p>
                <p className="fw-bold text-white">Si tienes dudas o problemas para generar tu factura contáctanos a atencionalinvitado@kataplum.com.mx<br/></p>
               </div>  
               <div className="col-sm-4" style={{textAlign:'center'}}>
                <img height={350} src={this.state.servurlassets + "billing.png"} />
               </div>
               <div className="col-sm-12 text-center">
                <a href='https://diniz.com.mx/diniz/FacturacionOpardiv/' className="btn btn-danger text-light fw-bold">Facturar en Línea</a>
               </div>  
               <div className="col-sm-12">
                     <br/><br/><br/><br/><br/><br/>
               </div>               
              </div>
             </div>
            </div>
        )
    }

}
export default Secc_facturacion