import React, { Component } from 'react';
import {Col, Modal, ModalHeader, ModalBody,Row} from 'reactstrap';
import './desktop.css';
import ReactPixel from 'react-facebook-pixel';

class Modal_Compra extends Component{

    componentDidMount() {
        // ReactPixel.init('1404238560446929');
    }

    render(){
        return(
            <div>
                <Modal className='modal-lg' isOpen={this.props.modal} toggle={this.props.changeModal}>
                    <ModalHeader toggle={this.props.changeModal} style={{backgroundColor:'#562C87'}}>
                     <h3 className='fw-bold' style={{color:'white'}}>{this.props.producto.title}</h3>
                    </ModalHeader>
                    <ModalBody style={{backgroundColor:'#DFDCF7'}}>
                        <Row >
                            <Col sm={6}>
                                <img alt='producto' style={{ width: '100%' ,height:'100'  }} src={this.props.servurlassets + this.props.producto.nombre + this.props.producto.extension}/>
                            </Col>
                            <Col sm={6}>
                                <div className='col-sm-12 col-12 text-center fw-lighter morado fw-bold' style={{height:'100px',fontSize:'18px',textAlign:'justify'}}>
                                    {this.props.producto.texto_boton}<br/><br/><br/><br/>
                                    
                                </div>
                                <div className='col-sm-12 col-12 text-center fw-lighter morado fw-bold'>
                                   Costo ${this.props.producto.monto}
                                </div>
                                <div className='col-sm-12 col-12 text-center'><br/>
                                    <button className='btn btn-danger fw-bold text-light' onClick={this.props.agregaCarrito.bind(this,this.props.producto.codigo,this.props.producto.title,this.props.producto.monto,this.props.producto.codigoPromocional)}>
                                        Agrega al Carrito
                                    </button>
                                </div>
                            </Col>
                        </Row>
                        
                    </ModalBody>
                </Modal>    
            </div>
        );
    }    
}

export default Modal_Compra