import React, { Component } from 'react';
import "./desktop.css";

class Secc_shows extends Component {
    constructor() {
        super();

        this.state = {
            servurlassets: "https://ecommercegd.blob.core.windows.net" + "/imagenes-k/",
            objetosShows: []
        };
    }

    shows = () => {
        let data = {
           modulo:'Shows' 
        };

        const requestInfo = {
            method: "POST",
            body: JSON.stringify(data),
            header: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
        };
        fetch(this.props.servurlbase + "/getServicios.php", requestInfo)
            .then((response) => response.json())
            .then((objetosShows) => {
                    this.setState({ objetosShows });                    
            })
            .catch((e) => console.log(e));
    };

    componentDidMount() {
        this.shows();
    }


    render() {

       

        return (
            <div>
             <div className='container-fluid'>
              <div className='row row_ bg-success'>
                <div className="col-sm-12">
                    <br/>
                    <p className='title_module'>Shows</p>
                </div>

                {this.state.objetosShows.length > 0
                ? this.state.objetosShows.map((objetos) => ( 

                    <div className='col-sm-6'style={{padding:'2em'}}>
                        <div className='row'>

                            <div className='col-sm-1'></div>
                            <div className="col-sm-10 rounded-pill borde_div" style={{background: '#ffee88'}}>
                              <div className='row text-center'>

                                <div className='col-sm-12 col-12 text-center'>
                                 <br/>
                                 <img src={this.props.servurlassets + objetos.img_principal} />
                                </div>
                                <div className='col-sm-12' style={{textAlign:'justify', textAlign:"center",minHeight:"130px"}}>
                                 <p className="fw-bold morado">{objetos.texto}</p>
                                </div>

                              </div>
                            </div>
                            <div className='col-sm-1'></div>


                            <div className='col-sm-1'></div>
                            <div className='col-sm-10'>
                             <img className='rounded-5 borde_div' style={{width:'100%'}} src={this.props.servurlassets + objetos.img} />
                            </div>
                            <div className='col-sm-1'></div>
                        </div>
                       </div>
        
                ))
                : []}
                <div className='col-sm-12'>
                  <br/><br/><br/><br/><br/>
                </div>
              </div>
             </div>
            </div>
        )
    }

}
export default Secc_shows