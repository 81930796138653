import React, { Component } from 'react';
import Swal from 'sweetalert2';
import validator from 'validator'


class Secc_cotiza extends Component {

    constructor() {
        super();

        this.state = {
            objetosLs: [],
        };
    }

    enviar = () => {
        document.getElementById("btn_enviar").disabled = true;
    
        var nombre = document.getElementById("nombre").value;
        var correo = document.getElementById("correo").value;
        var telefono = document.getElementById("telefono").value;
        var invitados = document.getElementById("invitados").value;

        if( !nombre || !correo || !telefono || !invitados ){
            Swal.fire("Error", "Llene todos los campos", "warning");
            document.getElementById("btn_enviar").disabled = false;
            return false;
        }

        if( invitados < 2 ){
            Swal.fire("Error", "Valor de invitados inválido", "warning");
            document.getElementById("btn_enviar").disabled = false;
            return false;
        }

        if (!validator.isEmail(correo)) {
            Swal.fire("Error", "Correo inválido", "warning");
            document.getElementById("btn_enviar").disabled = false;
            return false;
        } 
              
        let data = {
            cuerpo: "Asunto:<strong>CotizaciOn de Fiestas Kataplum</strong><br/><br/>Nombre del interesado: " + nombre + " <br/>Correo: " + correo + " <br/>TelEfono: " + telefono + " <br/>Cantidad de invitados: " + invitados,
            email: 'asuarez@grupodiniz.com.mx',
            asunto: "Contacto de invitado Portal ¡Kataplum!"
        }

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };

        fetch(this.props.servurlbase + '/sendCorreoContacto.php', requestInfo)
        .then((response) => {
            if(response){
                Swal.fire(
                    'Enviado',
                    'Se ha enviado su información',
                    'success'
                )
                document.getElementById("btn_enviar").disabled = false;
                document.getElementById("nombre").value = '';
                document.getElementById("correo").value = '';
                document.getElementById("telefono").value = '';
                document.getElementById("invitados").value = '';
            }
            else{
                Swal.fire("Error", "Error", "warning");
                document.getElementById("btn_enviar").disabled = false;
            }
        })
        .catch(e => console.log(e));
     
    }

    render() {
        return (
            <div>
             <div className='container-fluid'>
              <div className='row row_' style={{ backgroundImage: `url("https://ecommercegd.blob.core.windows.net/imagenes-k/bg-5.png")` }}>
                <div className="col-sm-4"></div>
                <div className="col-sm-4 text-center"><br/>
                  <img className="img-fluid" src={this.props.servurlassets + "festej.png"} />
                </div>
                <div className="col-sm-4"></div>
                <div className="col-sm-3 col-1"></div>
                <div className='col-sm-6 col-10 rounded-5' style={{background: '#53328B'}}>
                    <img className="img-fluid" src={this.props.servurlassets + "pleca.png"} />
                    <div className='row'>
                        <div className="col-sm-6">
                            <h3 style={{color:'white',textAlign:'center'}}>Cotiza Ahora</h3>
                            <br/>
                            <input type="text" id='nombre' style={{textAlign:'center'}} className="form-control" placeholder='Nombre completo'/>
                            <br/>
                            <input type="number" id='telefono' style={{textAlign:'center'}} className="form-control" placeholder='Teléfono'/>
                            <br/>
                            <input type="text" id='correo' style={{textAlign:'center'}} className="form-control" placeholder='Correo electrónico'/>
                            <br/>
                            <input type="number" id='invitados' style={{textAlign:'center'}} className="form-control" placeholder='Cantidad de invitados'/>
                            <br/>
                        </div>
                        <div className="col-sm-6"><br/><br/><br/>
                            <img className="img-fluid" src={this.props.servurlassets + "fotoFiesta.png"} />
                        </div>
                        <div className="col-sm-2 col-2 text-center" style={{ textAlign:'right' }}>
                        <input className='form-check-input'type="checkbox"/>
                        </div>
                        <div className="col-sm-10 col-10">
                        <p className='fw-bold' style={{color:'white'}}>Acepto recibir información sobre novedades y promociones de ¡KATAPLUM!</p>
                        </div>
                        <div className="col-sm-4 col-12">
                        <br />
                            <a className="btn btn-light btn-lg border" href="tel:800 881 8811" target="_blank">
                                <img width={25} src={this.props.servurlassets + "phone.svg"} />
                                <span className='fw-bold morado'> 800 881 8811</span>
                            </a><br/><br/>
                        </div>
                        <div className="col-sm-4 col-12 text-center">
                            <a id='btn_enviar' style={{cursor:'pointer'}} onClick={this.enviar.bind(this)}>
                                <img style={{height:'60%'}} alt='Enviar'  className="img-fluid" src={this.props.servurlassets + "enviar.png"} />
                            </a>
                        </div>
                        <div className="col-sm-4 col-12">
                            <br />
                            <a className="btn btn-light btn-lg border" href="https://api.whatsapp.com/send/?phone=%2B525526979865&text=%C2%A1Hola%21+Solicito+informaci%C3%B3n+de+fiestas..&app_absent=0" target="_blank">
                                <img width={30} src={this.props.servurlassets + "whattsapp.svg"} />
                                <span className='fw-bold morado'> 552 697 98 65</span> 
                            </a><br/><br/>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 text-center">
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>                    
                </div>
              </div>
             </div>
            </div>
        )
    }

}
export default Secc_cotiza